import axios from 'axios';
import queryString from 'qs';
import cleanDeep from 'clean-deep';
import {isEmpty, isUndefined} from 'lodash/lang';
import {assign, get} from 'lodash/object';
import Cookies from 'utils/cookies';
import {API_URL} from 'configs';
import {IAF_MAIN_URL} from 'configs/Environment';
import urljoin from 'url-join';

export const queryParse = (string) => {
  return queryString.parse(string, {ignoreQueryPrefix: true});
};

export const queryStringify = (obj, options) => {
  return queryString.stringify(obj, options);
};

export const makeSearchParams = (queries = {}) => {
  if (isEmpty(queries)) return {};

  if (typeof queries === 'string') {
    queries = queryParse(queries);
  }

  const {limit, offset, order, order_by, key, with_limit, ...rest} = queries;

  let params = {
    limit: isUndefined(limit) ? 20 : limit,
    offset: isUndefined(offset) ? 0 : offset,
    key: isUndefined(key) ? '' : key,
    with_limit: isUndefined(with_limit) ? '' : with_limit,
  };

  if (!isUndefined(order)) params.order = order;
  if (!isUndefined(order_by)) params.order_by = order_by;
  if (!isEmpty(rest))
    params.filter = {
      ...rest,
    };

  return params;
};

/**
 * Global axios defaults
 */
export const setAxiosConfig = () => {
  // axios setup
  axios.defaults.baseURL = API_URL;

  // bsid (browser unique ID)
  axios.defaults.headers.common['x-http-bsid'] = Cookies.bsid;
};

const http = (method = 'GET', url, data, options) => {
  let instance = axios.create();

  let args = {};

  // Set Request
  args.method = method;
  args.url = url;

  // Set Request Data
  if (method.toUpperCase() === 'GET') {
    args.params = cleanDeep(data);
  } else {
    args.data = data;
  }

  // Set options
  if (!isEmpty(options)) {
    args = assign({}, args, options);
  }

  // Set Token

  const session = Cookies.session;
  const captchaToken = Cookies.captchaToken || '';
  const token = get(session, 'token', '');

  args = assign(
    {},
    {
      headers: {
        'x-http-token': isEmpty(token) ? '' : token,
        'x-http-captcha-token': isEmpty(captchaToken) ? '' : captchaToken,
      },
    },
    args
  );

  return instance(args)
    .then(({data}) => data)
    .catch((error) => {
      let result;
      const response = get(error, 'response', {});

      if (!isEmpty(response)) {
        const status = get(error, 'response.status', '');
        const data = get(error, 'response.data.data', {});
        const message = get(error, 'response.data.errors.message', '');
        const code = get(error, 'response.data.errors.code', '');
        const token = get(error, 'response.data.token', '');

        result = {
          error: true,
          status: status,
          ...get(error, 'response.data.errors', {}),
        };

        if (!isEmpty(data)) result.data = data;
        if (!isEmpty(message)) result.message = message;
        if (!isEmpty(token)) result.token = token;
        if (!isEmpty(code)) {
          result.code = status === 500 || status === '500' ? 'NOT_FOUND' : code; // Blocking to show 500 error.;
        }

        if (code === 'invalid_session_token') {
          // @Note: Redirect to login page with 401 status
          // Clear session token
          Cookies.session = '';

          // @OLD: urljoin(IAF_MAIN_URL,`/error/${status}`)
          const redirectTo = `/login`;

          // @TODO: Redirect to login page with redirect url in the `src/utils/render-routes.js`
          window.location.replace(urljoin(IAF_MAIN_URL, redirectTo));
        } else if (code === 'invalid_captcha_token') {
          Cookies.captchaToken = '';
        } else if (status === 404 || status === '404') {
          window.location.replace(`/error/${status}`);
        } else if (status === 500 || status === '500') {
          window.location.replace(`/error/${status}`);
        }
      } else {
        result = error;
      }

      throw result;
    });
};

export default http;
